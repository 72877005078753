import React from 'react'
import { Fade, Zoom } from 'react-reveal'
import classNames from 'classnames'
import styled from 'styled-components'

import Page from '../templates/Page'
import PageSection from '../templates/PageSection'
import Contact from '../components/Contact'

import '../templates/page-header.css'
import './podcast-hosting.css'

const blogArticles = [
  {
    title: 'Music for Podcasts',
    url: 'https://live365.com/blog/music-for-podcasts',
    imageURL: 'https://live365.com/blog/content/images/2018/09/music-scaled.jpg'
  },
  {
    title: 'Podcast Equipment Guide for Beginners',
    url: 'https://live365.com/blog/podcast-equipment-guide-for-beginners',
    imageURL:
      'https://live365.com/blog/content/images/2018/09/podcast-equipment.jpg'
  },
  {
    title: 'Best Ways to Make Money Podcasting',
    url: 'https://live365.com/blog/best-ways-to-make-money-podcasting',
    imageURL:
      'https://live365.com/blog/content/images/2018/09/computer-money.jpeg'
  },
  {
    title: 'How to Record a Podcast',
    url: 'https://live365.com/blog/how-to-record-a-podcast',
    imageURL:
      'https://live365.com/blog/content/images/2018/06/microphone-1003561_640.jpg'
  },
  {
    title: 'How to Grow Your Podcast Audience',
    url: 'https://live365.com/blog/how-to-grow-your-podcast-audience',
    imageURL:
      'https://live365.com/blog/content/images/2018/10/marketing-scaled.jpg'
  },
  {
    title: 'How to Start a Podcast: What You Need to Know',
    url:
      'https://live365.com/blog/how-to-start-a-podcast-what-you-need-to-know',
    imageURL:
      'https://live365.com/blog/content/images/2018/10/microphone-2618102_640.jpg'
  },
  {
    title: 'Top Tips for Podcasting',
    url: 'https://live365.com/blog/top-tips-for-podcasting',
    imageURL: 'https://live365.com/blog/content/images/2018/10/tips-scaled.jpg'
  },
  {
    title: 'How to Create Your First Podcast',
    url: 'https://live365.com/blog/how-to-create-your-first-podcast',
    imageURL:
      'https://live365.com/blog/content/images/2018/10/first-podcast.jpeg'
  }
]

class PodcastHosting extends React.Component {
  render() {
    return (
      <Page
        className={classNames('podcast-hosting')}
        withHeaderBackgroundImage
        header={null}
      >
        <PageSection className={classNames('hardware-software')} style={{ paddingBottom: '4rem' }}>
          <div className={classNames('row', 'padded-section-content')}>
            <div className={classNames('column')}>
              <Fade top duration={700} fraction={0.9}>
                <p
                  className={classNames('section-text')}
                  style={{ fontSize: '1.125rem' }}
                >
                  Live365 for Podcasts offers an easy and powerful way to start
                  podcasting. Live365 enables podcasters to create, distribute,
                  and monetize their audience with no difficult technology to
                  learn.
                </p>
              </Fade>
            </div>
          </div>
          <Grid>
            {blogArticles.map((article, index) => (
              <Fade duration={700} delay={300} key={index}>
                <Zoom duration={700} delay={index * 100}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classNames('platform-link')}
                    href={article.url}
                    style={{
                      backgroundImage: `url(${article.imageURL})`
                    }}
                  >
                    <h1 className="blog-article-heading">{article.title}</h1>
                  </a>
                </Zoom>
              </Fade>
            ))}
          </Grid>
        </PageSection>

        <Contact
          className={classNames('page-section')}
          description={'Find out more about how to start a podcast.'}
          subject={'Podcast Hosting'}
        />
      </Page>
    )
  }
}

const Grid = styled.div`
  margin: 0 auto;
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  @media (max-width: 899px) {
    grid-template-columns: repeat(2, calc(50% - .5rem));
    grid-template-rows: repeat(4, 8rem);
    & h1 {
      font-size: 1rem;
    }
  }
  @media (min-width: 700px) {
    & h1 {
      font-size: 1.25rem;
    }
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(4, calc(25% - 3rem / 4));
    grid-template-rows: repeat(2, 10rem);
  }
`

export default PodcastHosting
